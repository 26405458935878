<template>
  <v-overlay
    :value="show"
  >
    <v-card
      width="500"
      light
    >
      <v-toolbar
        color="#333"
        dark
      >
        <div class="text-h5">
          Пересчет стат. стоимости
        </div>
      </v-toolbar>
      <v-card-text class="pa-6">
        Вы уверены, что хотите пересчитать статистическую стоимость товаров?
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="#333"
          text
          @click="close"
        >
          Отмена
        </v-btn>
        <v-btn
          color="#5CB7B1"
          dark
          @click="submit"
        >
          Пересчитать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>
<script>
import closeOnEscape from '@/mixins/close-on-escape.mixin.js'
import {mapGetters} from "vuex";
export default {
  mixins:[closeOnEscape],
  props:{
    show:{
      required:true,
      type:Boolean
    },
  },
  computed:{
    ...mapGetters({
      requests:"statistics/getRequests"
    })
  },
  methods:{
    submit(){
      Promise.all(this.requests).then(() => {
        this.$store.dispatch('statistics/recalcStatCost').then(() => {
          this.$snackbar({text:'Cтат. cтоимость обновлена', top:false, right: false})
          this.close()
        }).catch(() => {
          this.$snackbar({text:'Ошибка',color:'red', top:false, right: false})
        })
      })
    },
    close(){
      this.$emit('update:show', false)
    }
  }
}
</script>
